.main-container-etapas{
    width: 1192px;
    height: auto; /*auto*/
    margin-left: auto;
    margin-right: auto;
    /* border: solid 1px black; */
}

.gray-line-etapas {
    width: 100%;
    height: 1px;
    margin: 156px 0;
    background-color: #eee;
}

.etapas-cadastro-title {
    /* width: 482px;
    height: 72px; */
    /* margin: 112px 24px 32px 364px; */
    margin: 156px 24px 32px 0px;
    font-family: Montserrat;
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
  
.etapas-cadastro-subtitle {
    /* width: 421px;
    height: 48px; */
    /* margin: 32px 10.7px 56px 364px; */
    margin: 32px 10.7px 56px 0px;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}

.img-etapa {
    width: 1192px;
    height: 380px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    /* margin: 56px 364px 40px; */
    margin: 56px 0px 40px;
    padding: 0;
    object-fit: contain;
    border-radius: 24px;
    background-color: #ffe8e9;
}
  
.etapa-container {
    /* width: 990px; */
    width: auto;
    height: auto;
    display: flex;
    /* flex-direction: column; */
    /* flex-direction: row;
    justify-content: space-between;
    align-items: center; */
    /* gap: 8px; */
    /* margin: 40px 32px 156px 364px; */
    /* margin: 40px 0px 156px 0px; */
    /* padding: 40px 32px; */
    /* padding-left: 32px; */
    /* border-radius: 24px;
    border: solid 1px #f1f1f1;
    border: solid 1px #d10beb; */
}


.border-etapa1 {
    width: 990px;
    height: 296px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 8px;
    padding: 40px 32px; */
    border-radius: 24px;
    border: solid 1px #f1f1f1;
}

.border-etapa1:hover{
    background-color:#f1f1f1;
}

.border-etapa2 {
    width: 990px;
    height: 264px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* gap: 8px;
    padding: 40px 32px; */
    border-radius: 24px;
    border: solid 1px #f1f1f1;
}

.border-etapa2:hover{
    background-color:#f1f1f1;
}

.border-etapa3 {
    width: 990px;
    height: 296px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* gap: 8px;
    padding: 40px 32px; */
    border-radius: 24px;
    border: solid 1px #f1f1f1;
}

.border-etapa3:hover{
    background-color:#f1f1f1;
}

.border-etapa4 {
    width: 990px;
    height: 264px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* gap: 8px;
    padding: 40px 32px; */
    border-radius: 24px;
    border: solid 1px #f1f1f1;
}

.border-etapa4:hover{
    background-color:#f1f1f1;
}

  
.etapa-text-container {
    width: auto;
    height: auto;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* gap: 24px; */
    padding: 0;
    /* margin-top: 40px;;
    margin-left: 32px; */
    margin: 40px 0 40px 32px;
    /* border: solid 1px green; */
}

.emoji-cadastro {
    width: 32px;
    height: 32px;
    object-fit: contain;
    margin-bottom: 24px;
}

.emoji-border {
    /* width: 56px;
    height: 56px; */
    flex-grow: 0;
    /* margin: 0 154px 24px 0; */
    padding: 16px;
    border-radius: 56px;
    border: solid 1px #f1f1f1;
    /* background-color: #fff; */
  }
  
.etapa-text {
    width: 320px;
    height: 32px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    margin-bottom: 8px;
    /* border: solid 1px #0a06f5; */
}

.etapa-text-3 {
    /* width: 319px; */
    height: 32px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
  
.etapa-describe {
    /* width: 560px; */
    width: auto;
    height: auto;
    /* height: 32px; */
    /* margin-left: 32px; */
    display: flex;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    /* border:solid 1px rgb(235, 14, 135); */
}

.etapa2-describe {
    width: 617px;
    height: 64px;
    margin-left: 32px;
    margin-bottom: 40px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    /* border:solid 1px rgb(235, 14, 135); */
}

.etapa3-describe {
    width: 550px;
    height: 96px;
    margin-left: 32px;
    margin-bottom: 40px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    /* border:solid 1px rgb(235, 14, 135); */
}

.etapa-arrow-icon {
    width: 77px;
    height: 77px;
    /* margin-top: 77.5px;
    margin-bottom: 77.5px; */
    /* margin-left: 32px; */
    background-color: #fff;
}

.etapa-arrow-icon:hover {
    border-radius: 64px;
    background-color: #f1f1f1;
}

.etapa-arrow-icon:active {
    background-color: #ffe8e9;
}

.arrows-div{
    width: 202px;
    height:300px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    /* margin-top: -390px; */
    border-radius: 24px; 
    /* border: solid 1px #f1f1f1; */
    gap:16px;
    /* border: solid 1px #eb0b0b; */
    overflow:hidden;
    white-space:nowrap;
    margin-left: 1000px;
    margin-top: -300px;
}

.arrows-container{
    /* width: 202px;
    height: 232px; */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: -160px;
    /* border: solid 1px #f1f1f1; */

    /* border: solid 1px #340beb; */
}

.cursor-cadastro{
    cursor: pointer;
}


/* 
===========================
||       RESPONSIVO      ||
===========================
*/

@media only screen and (min-width: 360px) and (max-width: 1192px){

    .main-container-etapas{
        width: 328px;
        height: auto; /*auto*/
        margin-left: auto;
        margin-right: auto;
        /* border: solid 1px black; */
    }

    .etapas-cadastro-title {
        width: 280px;
        height: 36px;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        margin-top: 56px;
    }

    .etapas-cadastro-subtitle {
        width: auto;
        height: 28px;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
        margin: 16px 0px 0px 0px;
    }

    .img-etapa {
        width: 328px;
        height: 294px;
        margin: 40px 0px 32px;
        object-fit: contain;
    }

    .etapa-container {
        /* width: 990px; */
        width: auto;
        height: 350px;
        display: flex;
        /* flex-direction: column; */
        flex-direction: column;
        /* justify-content: space-between;
        align-items: flex-start; */
        /* gap: 8px; */
        /* margin: 40px 32px 156px 364px; */
        /* margin: 40px 0px 156px 0px; */
        /* padding: 40px 32px; */
        /* padding-left: 32px; */
        /* border-radius: 24px;
        border: solid 1px #f1f1f1; */
    }


    .border-etapa1 {
        width: auto;
        height: auto;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        /* gap: 16px;
        padding: 24px 352px 24px 16px; */
        border-radius: 24px;
        border: solid 1px #f1f1f1;
    }

    .etapa-text-container { 
        /* width: 296px;
        height: 96px; */
        width: auto;
        height: auto;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        /* gap: 16px; */
        margin: 24px 0 0px 16px;
        padding: 0;
    }

    .emoji-cadastro {
        width: 24px;
        height: 24px;
        object-fit: contain;
        margin-bottom: 16px;
    }

    .etapa-text {
        width: 296px;
        height: 32px;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        margin-bottom: 04px;
    }

    .etapa-text-3 {
        width: 296px;
        /* height: 64px; */
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        margin-bottom: 24px;
    }

    .etapa-describe {
        /* width: 296px;
        height: 48px; */
        width: 300px;
        height: auto;
        display: flex;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
        margin-bottom: 24px;
    }

    /* .etapa-describe-mobile1{
        visibility: hidden;
        position: relative;
    }

    .etapa-describe-mobile1:after {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        content: "Você preenche o formulário com as suas\A informações e seu cardápio.";
        white-space: pre-wrap;
    } */

    /* .etapa-describe-mobile2{
        visibility: hidden;
        position: relative;
    }

    .etapa-describe-mobile2:after {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        content: "Vamos analisar todos os seus dados, caso não\A tenha nenhum problema seu\A cadastro será aprovado e será enviado o\A contrato para o e-mail cadastrado.";
        white-space: pre-wrap;
    } */

    .etapa-describe-mobile3{
        width: 300px;
        height: auto;
        display: flex;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 11.5px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
        margin-bottom: 24px;
        /* visibility: hidden;
        position: relative; */
        margin-bottom: 0;
    }

    /* .etapa-describe-mobile3:after {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        content: "Após a confirmação da assinatura eletrônica do\A contrato, é iniciada a montagem do seu\A restaurante na plataforma e em poucos dias, você receberá as instruções para começar a vender.";
        white-space: pre-wrap;
    } */

    /* .etapa-describe-mobile4{
        visibility: hidden;
        position: relative;
    }

    .etapa-describe-mobile4:after {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        content: "Parabéns! Seu restaurante está pronto para\a receber pedidos.";
        white-space: pre-wrap;
    } */

    .arrows-div{
        width: 112px;
        height: 48px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
        padding: 0;
        /* margin-top: 160px; */
        /* margin-left: -991px; */
        /* margin-top: 32px; */
        overflow: hidden;
        white-space: nowrap;
        margin:0;
        margin-top: -60px;        
    }

    /* arrow fa1 margin-top: 242px;
    margin-left: -430px; */

    .etapa-arrow-icon {
        width: 48px;
        height: 48px;
        flex-grow: 0;
        object-fit: contain;
    }

}

