@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;1,100&display=swap');


*{
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    scroll-behavior: smooth;
}



body { 
    overflow-x: hidden; 
}


.main-container{
    width: 1192px;
    height: auto; 
    margin-left: auto;
    margin-right: auto;
    /* border: solid 1px black; */
}

.div-whats{
    width: auto;
    height: auto; 
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    right:50px;
    z-index:1000;
}

.whats{
    /* width: 65px;
    height: 65px; */
    cursor: pointer;
    border-radius:60px;
    background-color: #25d366;
}

.whats:active{
    transform: translateY(0px);
}


.main-container-aumente-vendas{
    width: 1192px;
    height: auto; 
    margin-left: auto;
    margin-right: auto;
    margin-top: -840px;
    /* border: solid 1px black; */
}

.no-show-desktop {
    display: none;
}

.no-show-mobile{
    display: block;
}
  
.aumente-as-vendas-text {
    width: 510px;
    height: 144px;
    margin: 203px 21px 32px 0px;
    object-fit: contain;
    font-family: Montserrat;
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    display: flex;
    flex-flow: wrap;
    overflow: hidden;
    /* display: inline-block;
    max-width: 100%; */
    /* display: inline-block;
    max-width: 100%;
    overflow: hidden;
	white-space: nowrap;
  text-overflow: ellipsis; */
  /* border: solid 1px blueviolet; */
}
  
.venda-mais-text {
    width: 422px;
    height: 144px;
    margin: 0px 9.7px 56px 0px;
    object-fit: contain;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    /* border: solid 1px blueviolet; */

}
  
.venda-mais-text .text-style-1 {
    font-weight: bold;
    color: #000;
    text-decoration: underline;
}

.quero-conhecer-btn {
    width: 199px;
    height: 72px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0px 105px 0px 0px;
    padding: 24px 32px;
    object-fit: contain;
    border-radius: 56px;
    background-color: #d7232c;
    cursor: pointer;
}

.quero-conhecer-btn:hover{
    background-color: #c32027;
  }
  
.quero-conhecer-text {
    width: 135px;
    height: 24px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.graphic-div{
    width: auto;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    margin-top: 56px;
}

.graphic-div2{
    /* width: 1192px; */
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    margin-top: -730px;
    overflow: hidden;
}

.container-img-grafico{
    width: 1390px;
    height: auto; 
    margin-left: auto;
    margin-right: auto;
}


.pink-bg {
    width: 52%;
    height: 755px;
    /* margin: 16px 0 40px 54px; */
    /* padding: 33px 256px 94px 78px; */
    /* object-fit: contain; */
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #ffe8e9;
    z-index: 99;
}

.imagem-hero {
    /* width: 794px;
    height: 665px; */
    /* margin: 237px 257px 77px 0; */
    margin-right: -30px;
    /* object-fit: contain; */
    z-index: 999;
}

.aumente-vendas-img{
  /* width: 888px; */
  /* width: 1192px;
  height: auto; */
  /* width: 992px; */
  height: auto;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: -500px;
  justify-content: flex-end;
  /* border: solid 1px rgb(12, 240, 31); */
}

.porque-appedidos-container2{
    width: 1192px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top:-100px;
    /* border: solid 1px black; */
}

.porque-appedidos-text2 {
    width: 425px;
    height: 144px;
    margin: 300px 0px 32px;
    object-fit: contain;
    font-family: Montserrat;
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
  
.somos-uma-plataforma{
    width: 451px;
    height: 96px;
    margin: 32px 25px 64px 0px;
    object-fit: contain;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}

.row1{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.card-row-1 {
    /* width: 381px; */
    min-width: 328px;
    height: 312px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    /* margin: 64px 24px 24px 364px; */
    margin: 0px 0px 24px 0px;
    padding: 40px 21px 40px 32px;
    object-fit: contain;
    border-radius: 24px;
    border: solid 1px #f1f1f1;
    background-color: #fff;
}

.card-row-1:hover {
    background-color:#f1f1f1;
}


.emoji-cards {
    width: 72px;
    height: 72px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 24px;
    object-fit: contain;
    /* border-radius: 64px; */
    /* border: solid 1px #f1f1f1; */
    /* background-color: #fff; */
}

.contrato-title {
    width: 142px;
    height: 16px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}

.contrato-text {
    width: 276px;
    height: 96px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}

.pagamento-app-title {
    /* width: 178px; */
    height: 16px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}
  
.pagamento-app-text {
    width: 288px;
    height: 96px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}

.custo-beneficio-title {
    width: 199px;
    height: 16px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}
  
.custo-beneficio-text {
    width: 277px;
    height: 96px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}

.row2{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.card-row-2 {
    /* width: 381px; */
    min-width: 328px;
    height: 312px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    margin: 0px 0px 24px 0px;
    padding: 40px 21px 40px 32px;
    object-fit: contain;
    border-radius: 24px;
    border: solid 1px #f1f1f1;
    background-color: #fff;
}
.card-row-2:hover {
    background-color:#f1f1f1;
}

.facil-usar-title {
    width: 106px;
    height: 16px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}
  
.facil-usar-text {
    width: 274px;
    height: 96px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}

.nao-precisa-computador-title {
    width: 233px;
    height: 16px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}
  
.nao-precisa-computador-text {
    width: 295px;
    height: 96px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}

.sem-enrolacao-title {
    width: 125px;
    height: 16px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}
  
.sem-enrolacao-text {
    width: 311px;
    height: 96px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}
  
.row3{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.card-row-3{
    /* width: 381px; */
    min-width: 328px;
    height: 312px;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start;
    align-items: flex-start; */
    gap: 32px;
    margin: 0px 24px 0px 0px;
    /* padding: 40px 21px 40px 32px; */
    padding: 40px 21px 40px 32px;
    object-fit: contain;
    border-radius: 24px;
    border: solid 1px #f1f1f1;
    background-color: #fff;
}
.card-row-3:hover {
    background-color:#f1f1f1;
}


.seguranca-title {
    width: 90px;
    height: 16px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
  }
  
.seguranca-text {
    width: 274px;
    height: 96px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}

.seu-jeito-title {
    width: 100px;
    height: 16px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
  }
  
.seu-jeito-text {
    width: 295px;
    height: 96px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}

.gray-line {
    width: 100%;
    height: 1px;
    margin: 156px 0;
    background-color: #eee;
}

.red-background-container{
    width: 100%;
    height: 578px;
    /* margin: 156px 0 0; */
    /* padding: 0 1132px 198px 364px; */
    background-color: #d7232c;
    margin-top: 156px;
}

.main-container-red{
    width: 1192px;
    height: auto; 
    margin-left: auto;
    margin-right: auto;

    /* border: solid 1px black; */
}

.red-background-title {
    width: 369px;
    height: 144px;
    /* margin: 112px 55px 32px 364px; */
    /* margin-top: 156px; */
    padding: 156px 0 0 0;
    object-fit: contain;
    font-family: Montserrat;
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #fff;
}
  
.red-background-text {
    width: 424px;
    height: 48px;
    margin: 32px 0 64px 0;
    object-fit: contain;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #f2b7ba;
}

.depoimentos-container{
    /* width: 888px; */
    width: 1192px;
    height: auto;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: -120px;
    justify-content: flex-start;
    /* border: solid 1px rgb(12, 240, 31); */
}

.depoimento-video {
    width: 280px;
    height: 332px;
    display: flex;
    margin-right: 24px;
    margin-bottom: 32px;
    object-fit: contain;
    border-radius: 24px;
    border:none;
}

.depoimento-title {
    width: 270px;
    height: 16px;
    object-fit: contain;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* border: solid 1px #1c0506; */
}

.depoimento-cidade {
    width: 270px;
    height: 16px;
    margin-top: 16px;
    object-fit: contain;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    /* border: solid 1px #1c0506; */
}

.parceiros-container {
    /* width: 1920px; */
    width: 1192px;
    height: auto;
    /* margin: 153px 0 156px; */
    background-color: #fff;
    /* border: solid 1px rgb(235, 14, 135); */
}
  
.parceiros-title {
    width: 235px;
    height: 72px;
    /* margin: 112px 1321px 32px 364px; */
    margin: 0px 0px 32px 0px;
    font-family: Montserrat;
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
  
.parceiros-text {
    width: 376px;
    height: 96px;
    /* margin: 32px 1180px 64px 364px; */
    margin: 0px 0 64px 0px;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
  
.parceiros-text .text-style-1 {
    font-weight: 500;
    color: #707070;
}

.carousel-marcas {
    width: 1192px;
    height: 136px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    /* border: solid 1px #0ca8f0; */
}


.faq-01 {
    width: 100%;
    /* height: 272px; */
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 72px;
    margin: 40px 0 0;
    /* border: solid 1px rgb(144, 13, 231); */
}

.faq-02{
    width: auto;
    height: 272px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 72px;
    /* margin: 312px 0 0; */
    /* padding: 72px 364px 0; */
    border: solid 1px #eee;
}

.red-background-container2{
    width: 100%;
    height: 100%;
    margin: 156px 0 0;
    padding: 106px 0 1px;
    background-color: #d7232c;
}
  
.vem-pra-appedidos-title {
    width: 489px;
    height: 72px;
    /* margin: 0 18px 24px 364px; */
    margin: 0 18px 24px 0px;
    font-family: Montserrat;
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    /* border:solid 1px yellow */
}
  
.vem-pra-appedidos-subtitle {
    width: 469px;
    height: 48px;
    /* margin: 24px 38px 56px 0px;
    padding: 41px 321px 5px 0; */
    display: inline;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #ffdede;
    /* border:solid 1px rgb(12, 240, 31); */
}
  
.vem-pra-appedidos-subtitle .text-style-1 {
    font-weight: bold;
    text-decoration: underline;
    color: #fff;
}

.white-box-rectangle {
    /* width: 432px; */
    width: 385px;
    /* height: 484px; */
    height: 474px;
    margin: 56px 0px 72px 0px;
    /* display: flex; */
    /* padding: 40px 0px 40px 32px; */
    border-radius: 24px;
    border: solid 1px #f1f1f1;
    background-color: #fff;
}

.white-box-content{
    width: auto;
    height: auto;
    margin: 40px 0px 40px 32px;
}

.div-porcentagem{
    width: 111px;
    height: auto;
    margin: 0;
    display: flex;
}
  
.valor-porcentagem{
    width: auto;
    height: 72px;
    font-family: Montserrat;
    font-size: 72px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #1c0506;
    display: flex;

}

.valor-porcentagem-full{
    width: auto;
    height: 72px;
    font-family: Montserrat;
    font-size: 72px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #aaa;
    display: flex;
    text-decoration: line-through;
    text-decoration-thickness: 3px;

}

.valor-porcentagem-desconto{
    width: auto;
    height: 72px;
    font-family: Montserrat;
    font-size: 72px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #1c0506;
    display: flex;
}
  
.simbolo-porcentagem {
    width: 29px;
    height: 48px;
    margin: 0 0 32px 4px;
    font-family: Montserrat;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #1c0506;
    display: flex;
}

.simbolo-porcentagem-promissao {
    width: 29px;
    height: 48px;
    margin: 0 0 32px 4px;
    font-family: Montserrat;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #aaa;
    display: flex;
}

.simbolo-porcentagem-promissao1 {
    width: 29px;
    height: 48px;
    margin: 0 0 32px 4px;
    font-family: Montserrat;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #1c0506;
    display: flex;
}

.text-de{
    width: 16px;
    height: 12px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #aaa;
    }

.text-por{
    width: 16px;
    height: 12px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    padding-left:8px;
    color: #1c0506;
    }

.div-text-porcentagem {
    width:auto;
    height: auto;
    display: inline;
    /* border: solid 1px #000; */
}
  
.text-porcentagem {
    width: 211px;
    height: 64px;
    margin: 8px 41px 32px 0;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #353535;
}

.coluna {
    display: flex;
    flex-direction: column;
}

 .row {
    display: flex;
    flex-direction: row;
 }

 .periodo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 15px 0 31px 8px;
    padding: 8px 12px;
    border-radius: 16px;
    background-color: #eddfff;
 }
 
 .periodo-text {
    width: 93px;
    height: 10px;
    font-family: Montserrat;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #5e26a4;
    /* padding-bottom: 7px; */
}
 
.porcentagem-pagamento-online {
    width: 252px;
    /*height: 16px;*/
    margin: 32px 0 24px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}
  
.porcentagem-pagamento-online .text-style-1 {
    font-weight: bold;
    color: #1c0506;
}
  
.mensalidade {
    width: 149px;
    height: 16px;
    margin: 24px 103px 0 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #aaa;
}

.delivery-text {
    width: auto;
    height: 16px;
    margin: 24px 0 24px 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #aaa;
}
  
.cadastrar-rest {
    width: 190px;
    height: 16px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}
  
.btn-cadastro-rest {
    width: 234px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 64px;
    background-color: #d7232c;
    text-decoration: none;
}

.btn-cadastro-rest:hover{
    background-color: #c32027;
}

.btn-promotion {
    width: 170px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 64px;
    background-color: #fff;
    text-decoration: none;
    margin: 12px 84px 0 0;
    padding: 12px 32px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #d7232c;
}

.btn-promotion:hover {
    background-color: #ffe8e9;
    cursor: pointer;
}

.seta {
    width: 20px;
    height:22px;
    color: #353535;
    margin-right:12px;
    margin-left:12px;
    margin-top: 0px;
    text-align: left;
    align-self: center;
    color: #353535; 
     }



.fature-mais{
    display: flex;
    margin-top: -620px;
    justify-content: flex-end;
}

.img-fature-mais{
    width: 679px;
    height: 775px;
    object-fit: contain;
}

.acompanhar-processo-container {
    width: auto;
    height: auto;
    margin-top: -83px;
    /* padding: 72px 0px 0px 0px; */
    background-color: #fff;
    /* border: solid 1px #0ca8f0; */
}
  
.emojiPensando {
    width: 32px;
    height: 32px;
    margin: 0 214px 24px 0;
    object-fit: contain;
    font-family: Montserrat;
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #ffdede;
}
  
.acompanhar-processo-text {
    width: 246px;
    height: 80px;
    margin: 24px 0 32px;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #1c0506;
}
  
.btn-acompanhar-processo {
    width: 206px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 32px 40px 156px 0;
    /* padding: 16px 32px; */
    object-fit: contain;
    border-radius: 64px;
    background-color: #d7232c;
    text-decoration: none;
}

.btn-acompanhar-processo:hover{
    background-color: #c32027;
}
  
.btn-acompanhar-processo-text {
    width: 162px;
    height: 16px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

/* 
===========================
||       MODAL           ||
=========================== 
*/

.modal-promocao {
    /* width: 100vw; */
    height: 100vh;
    width: 100%;
    /* height: auto; */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: fixed;
    top: 0;
    left: 0px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.4);
    align-items: flex-end;
    overflow:auto;  -webkit-animation: fadeIn 0.3s;
    animation: fadeIn 0.3s;
  }
  
  /* Add animation (fade in the popup) */
  @-webkit-keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  
  @keyframes fadeIn {
    from {opacity: 0;}
    to {opacity:1 ;}
  }  
  
  
  
  .escondidoDireita {
    /* -webkit-transform: translateX(100vw);
            transform: translateX(100vw); */
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    visibility: hidden;
    animation: fadeOut ease 0.4s;
    -webkit-animation: fadeOut ease 0.4s;
    -moz-animation: fadeOut ease 0.4s;
    -o-animation: fadeOut ease 0.4s;
    -ms-animation: fadeOut ease 0.4s;
    
  }
  
  @keyframes fadeOut {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
    }
  }
  
  .modal-promo{
    width: 903px;
    height: 970px;
    background-color: #fff;
    border-top-left-radius: 30px;
    padding: 0px;
}
      
  .modal-content-promo{
    width: auto;
    height: auto;
    margin-left: 56px;
    }
  
  .closeBtn {
    width: 40px;
    height: 40px;
    object-fit: contain;
    /* margin-top: 72px; */
    /* margin-bottom: 40px; */
    /* font-size: 2.5rem;
    cursor: pointer;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; */
    cursor: pointer;
  }

  .modal-title-promo {
    width:auto;
    margin: 24px 42px 8px 0px;
    font-family: Montserrat;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.modal-subtitle-promo {
    width: 396px;
    margin: 0px 451px 0px 0px;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #5e26a4;
}

.modal-text-promo {
    width: 368px;
    height: 192px;
    margin: 24px 420px 346px 0px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}
  
.modal-text1-promo {
    font-weight: bold;
    margin-bottom: 24px;
}

.p-margin {
    margin-bottom: 24px;
}

.btn-entendi {
    width: 138px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    gap: 10px;
    border-radius: 64px;
    background-color: #d7232c;
    text-decoration: none;
    cursor: pointer;
    margin-top: 250px;
}

.btn-entendi:hover{
    background-color: #c32027;
}

.entendi {
   
    width: 74px;
    height: 16px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

/* 
===========================
||       RESPONSIVO      ||
=========================== 
*/

@media only screen and (max-width: 1192px){

    .main-container{
        width: 100%;
    }
}

@media only screen and (min-width: 360px) and (max-width: 1192px){

    .main-container{
        width: 328px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }

    .main-container-aumente-vendas{
        width: 328px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
    }

    .div-whats{
        width: auto;
        height: auto; 
        margin-bottom: 15px;
        display: flex;
        justify-content: flex-end;
        position: fixed;
        bottom: 0;
        right:20px;
        z-index:1000;
    }
    
    .whats{
        /* width: 50px;
        height: 50px; */
        cursor: pointer;
        border-radius:60px;    
    }

    .no-show-mobile {
        display: none;
    }
    .no-show-desktop{
        display: block;
    }

    .card-row-1{
        display: none;
    }
    .card-row-2{
        display: none;
    }

    .card-row-3{
        display: none;
    }

    .aumente-as-vendas-text {
        width: 328px;
        height: 72px;
        margin: 24px 16px 8px 0;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #000;
      }


    .venda-mais-text {
        width: 328px;
        height: 56px;
        margin:0;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
        margin-bottom: 24px;
      }
      
      .venda-mais-text .text-style-1 {
        font-weight: bold;
        color: #000;
        text-decoration: underline;
        text-decoration-color: #707070;
      }

      .bandeira{
        width: 20px;
        height: 14px;
      }

      .quero-conhecer-text {
        width: 102px;
        height: 16px;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
    }

    .quero-conhecer-btn {
        width: 328px;
        height: 48px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin: 0px 0px 24px 0px;
        padding: 0;
        border-radius: 64px;
        background-color: #d7232c;
      }

    .graphic-div{
        width: auto;
        height: auto;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        align-content: flex-end;
        margin-top: 0;
    }

    .pink-bg {
        /* width: 360px; */
        width: 100%;
        height: 393px;
        /* margin: 49px 0 56px;
        padding: 30px 4px 0 0; */
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        background-color: #ffe8e9;
        margin-top: 0px
    }

    .graphic-div2-mobile{
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        align-content: flex-end;
        margin-top: -425px;
        overflow: hidden;
        position: relative;
        z-index: 999;
    }

    .imagem-hero-mobile {
        width: 360px;
        height: 393px;
        margin: 24px 0 0px;
        object-fit: contain;
    }
    

    .porque-appedidos-container2 {
        width: 328px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
        margin-top:60px;
        padding: 0;
        background-color: #fff;
        /* border: solid 1px rgb(0, 0, 0); */
    }

    .porque-appedidos-text2 {
        width: 212px;
        height: 72px;
        flex-grow: 0;
        object-fit: contain;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        margin:0;
    }

    .somos-uma-plataforma {
        width: 299px;
        height: 56px;
        flex-grow: 0;
        object-fit: contain;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
        margin:0;
    }

    .row1{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-top: 28px;
    }

    .card-contrato {
        width: 328px;
        height: 220px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0;
        /* margin: 40px 16px 16px;
        padding: 24px 16px; */
        object-fit: contain;
        border-radius: 24px;
        border: solid 1px #f1f1f1;
        background-color: #fff;
        margin-bottom: 16px;
        padding:0;
    }

    .emoji-cards {
        width: 48px;
        height: 48px;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* padding: 24px; */
        object-fit: contain;
        /* border-radius: 64px;
        border: solid 1px #f1f1f1; */
        background-color: #fff;
        padding:0;
        margin: 24px 0 24px 16px ;
    }

    .contrato-title{
        margin: 0 0 12px 16px;
    }

    .contrato-text {
        width: 296px;
        height: 72px;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
        margin-left: 16px;
    }

   .card-app {
        width: 328px;
        height: 196px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0;
        /* margin: 16px;
        padding: 24px 16px; */
        object-fit: contain;
        border-radius: 24px;
        border: solid 1px #f1f1f1;
        background-color: #fff;
        margin-bottom: 16px;
        padding:0;
    }

    .pagamento-app-title {
        margin: 0 0 12px 16px;
    }

    .pagamento-app-text {
        width: 296px;
        height: 48px;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
        margin-left: 16px;
        /* visibility: hidden;
        position: relative; */
    }

    
    /* .pagamento-app-text:after {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        content: "Receba os pagamentos direto pelo aplicativo \A e garanta o recebimento e agilidade na entrega.";
        white-space: pre-wrap;
    } */

    .card-custo-beneficio {
        width: 328px;
        height: 196px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0;
        /* margin: 16px;
        padding: 24px 16px; */
        object-fit: contain;
        border-radius: 24px;
        border: solid 1px #f1f1f1;
        background-color: #fff;
        margin-bottom: 16px;
        padding:0;
    }

    .custo-beneficio-title {
        width: 198px;
        margin: 0 0 12px 16px;
    }

    .custo-beneficio-text {
        /* width: 316px; */
        height: 48px;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
        margin-left: 16px;
        /* visibility: hidden;
        position: relative; */
    }
    /* .custo-beneficio-text:after {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        content: "Pague apenas sobre os resultados gerados pelo\A aplicativo, sem taxas fixas.";
        white-space: pre-wrap;
    } */

    .facil-usar-card {
        width: 328px;
        height: 196px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0;
        /* margin: 16px;
        padding: 24px 16px; */
        object-fit: contain;
        border-radius: 24px;
        border: solid 1px #f1f1f1;
        background-color: #fff;
        margin-bottom: 16px;
        padding:0;
    }

    .facil-usar-title {
        margin: 0 0 12px 16px;
    }

    .facil-usar-text {
        width: 296px;
        height: 48px;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
        margin-left: 16px;
    }

    .nao-precisa-computador-card {
        width: 328px;
        height: 196px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0;
        object-fit: contain;
        border-radius: 24px;
        border: solid 1px #f1f1f1;
        background-color: #fff;
        margin-bottom: 16px;
        padding:0;
    }

    .nao-precisa-computador-title {
        margin: 0 0 12px 16px;
    }

    .nao-precisa-computador-text {
        width: 296px;
        height: 48px;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
        margin-left: 16px;
        /* visibility: hidden;
        position: relative; */
    }

    /* .nao-precisa-computador-text:after {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        content: "Gerencie o seu delivery através do seu celular \A com o Gestor de Negócios.";
        white-space: pre-wrap;
    } */

    .sem-enrolacao-card {
        width: 328px;
        height: 220px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0;
        object-fit: contain;
        border-radius: 24px;
        border: solid 1px #f1f1f1;
        background-color: #fff;
        margin-bottom: 16px;
        padding:0;
    }

    .sem-enrolacao-title {
        margin: 0 0 12px 16px;
    }

    .sem-enrolacao-text {
        width: 296px;
        height: 72px;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
        margin-left: 16px;
        visibility: hidden;
        position: relative;
    }

    .sem-enrolacao-text:after {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        content: "O aplicativo possui um sistema de mensagens\A que permite fazer contato com o cliente casa\A haja necessidade.";
        white-space: pre-wrap;
    }

    .seguranca-card {
        width: 328px;
        height: 196px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0px;
        object-fit: contain;
        border-radius: 24px;
        border: solid 1px #f1f1f1;
        background-color: #fff;
        margin-bottom: 16px;
        padding:0;
    }

    .seguranca-title {
        margin: 0 0 12px 16px;
    }

    .seguranca-text {
        width: 296px;
        height: 48px;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
        margin-left: 16px;
        visibility: hidden;
        position: relative;
    }

    .seguranca-text:after {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        content: "Os clientes precisam confirmar o cadastro com\A uma mensagem automático no telefone.";
        white-space: pre-wrap;
    }

    .seu-jeito-card {
        width: 328px;
        height: 196px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0;
        object-fit: contain;
        border-radius: 24px;
        border: solid 1px #f1f1f1;
        background-color: #fff;
        margin-bottom: 16px;
        padding:0;
    }

    .seu-jeito-title{
        margin: 0 0 12px 16px;
    }

    .seu-jeito-text {
        width: 296px;
        height: 48px;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
        margin-left: 16px;
        visibility: hidden;
        position: relative;
    }
    .seu-jeito-text:after {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        content: "Crie produtos, promoções e configurações do\A jeito que melhor atenda o seu negócio.";
        white-space: pre-wrap;
    }

    .red-background-container {
        /* width: 360px; */
        width: 100%;
        height: 380px;
        margin: 125px 0 196px;
        /* padding: 56px 96px 208px 16px; */
        background-color: #d7232c;
    }


    .main-container-red{
        width: 328px;
    
        /* border: solid 1px black; */
    }

    .red-background-title {
        width: 185px;
        height: 72px;
        /* margin: 0 63px 16px 0; */
        margin-top: 56px;
        object-fit: contain;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        padding: 56px 0 0 0;
    }

    .red-background-text {
        width: 248px;
        height: 28px;
        margin: 16px 0 0;
        object-fit: contain;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #f2b7ba;
    }

    .depoimentos-container{
        /* width: 888px; */
        width: 100%;
        height: auto;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        margin-top: -360px;
        justify-content: flex-start;
        overflow: hidden;
        overflow-x:auto;
        scrollbar-width: thin;
        /* border: solid 1px rgb(99, 12, 240); */
    }
    
    /* Hide scrollbar for Chrome, Safari and Opera */
    .depoimentos-container::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .depoimentos-container {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .depoimento-video {
        width: 280px;
        height: 332px;
        flex-grow: 0;
        /* margin: 268px 16px 32px; */
        display: flex;
        margin-right: 16px;
        margin-bottom: 32px;
        object-fit: contain;
    }

    .gray-line {
        width: 100%;
        height: 1px;
        margin: 56px 0;
        background-color: #eee;    
    }

    .parceiros-container {
        /* width: 1920px; */
        width: 328px;
        height: auto;
        /* margin: 153px 0 156px; */
        background-color: #fff;
        /* border: solid 1px rgb(14, 18, 235); */
    }

    .parceiros-title {
        width: 118px;
        height: 36px;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        margin-bottom: 16px;
    }

    .parceiros-text {
        width: 219px;
        height: 56px;
        margin: 0px 0 40px 0px;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        
    }
      
    .parceiros-text .text-style-1 {
        font-weight: 500;
        color: #707070;
    }

    .carousel-marcas {
        width: 360px;
        height: 136px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    .red-background-container2 {
        width: 100%;
        height: 616px;
        /* margin: 56px 0 40px;
        padding: 56px 16px 40px; */
        margin: 56px 0 0px;
        padding: 56px 0px 0px;
        background-color: #d7232c;
    }

    .vem-pra-appedidos-title {
        width: 246px;
        height: 36px;
        margin: 0 84px 16px 0;
        object-fit: contain;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }

    .vem-pra-appedidos-subtitle {
        width: 300px;
        height: 28px;
        margin: 16px 54px 40px 0;
        display: block;
        object-fit: contain;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #ffdede;
    }
    
    .white-box-rectangle {
        width: 328px;
        height: 490px;
        margin: 40px 0 0;
        /* padding: 40px 32px; */
        border-radius: 24px;
        border: solid 1px #f1f1f1;
        background-color: #fff;
    }

    .white-box-content{
        width: auto;
        height: auto;
        margin: 24px 0px 40px 32px;
    }


    .btn-cadastro-rest {
        width: 264px;
        height: 48px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin:0;
        /* padding: 16px 32px; */
        border-radius: 64px;
        background-color: #d7232c;
    }

    .acompanhar-processo-container {
        width: auto;
        height: auto;
        margin: 0;
        /* margin-top: -83px; */
        /* padding: 72px 0px 0px 0px; */
        background-color: #fff;
        /* border: solid 1px #0ca8f0; */
    }

    .emojiPensando {
        width: 24px;
        height: 24px;
        margin: 40px 272px 16px 0px;
        object-fit: contain;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #ffdede;
    }

    .acompanhar-processo-text {
        width: 164px;
        height: 64px;
        margin: 16px 0px 24px 0px;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #1c0506;
    }

    .btn-acompanhar-processo {
        margin: 24px 90px 56px 0px;
    }

    /* 
===========================
||       MODAL           ||
=========================== 
*/

.modal-promocao {
    /* width: 100vw; */
    height: 100vh;
    width: 100%;
    /* height: auto; */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: fixed;
    top: 0;
    left: 0px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.4);
    align-items: flex-end;
    overflow:auto;  -webkit-animation: fadeIn 0.3s;
    animation: fadeIn 0.3s;
  }
  
  /* Add animation (fade in the popup) */
  @-webkit-keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  
  @keyframes fadeIn {
    from {opacity: 0;}
    to {opacity:1 ;}
  }  
  
  
  
  .escondidoDireita {
    /* -webkit-transform: translateX(100vw);
            transform: translateX(100vw); */
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    visibility: hidden;
    animation: fadeOut ease 0.4s;
    -webkit-animation: fadeOut ease 0.4s;
    -moz-animation: fadeOut ease 0.4s;
    -o-animation: fadeOut ease 0.4s;
    -ms-animation: fadeOut ease 0.4s;
    
  }
  
  @keyframes fadeOut {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
    }
  }
  
    .modal-promo{
    width: 100%;
    height: 100vh;
    background-color: #fff;
    border-top-left-radius: 0px;
    padding: 0px;
}
      
    .modal-content-promo{
    width: auto;
    height: auto;
    margin-left: 0px;
    padding: 16px;
    }
  
    .modal-text-mobile{
        padding: 0 0 0 8px;
    }

  .closeBtn {
    width: 40px;
    height: 40px;
    object-fit: contain;
    /* margin-top: 72px; */
    /* margin-bottom: 40px; */
    /* font-size: 2.5rem;
    cursor: pointer;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; */
    cursor: pointer;
  }

  .modal-title-promo {
    width:auto;
    margin: 24px 42px 8px 0px;
    font-family: Montserrat;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.modal-subtitle-promo {
    width: 289px;
    margin: 0px 451px 0px 0px;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #5e26a4;
}

.modal-text-promo {
    width: 312px;
    height: 192px;
    margin: 24px 24px 86px 0px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}
  
.modal-text1-promo {
    font-weight: bold;
    margin-bottom: 24px;
}

.p-margin {
    margin-bottom: 24px;
}

.btn-entendi {
    width: 328px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    gap: 10px;
    border-radius: 64px;
    background-color: #d7232c;
    text-decoration: none;
    cursor: pointer;
    margin-top: 0px;
    /* margin: 86px 16px 16px; */
}

.btn-entendi:hover{
    background-color: #c32027;
}

.entendi {
    width: 74px;
    height: 16px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.periodo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 82px 0 31px -243px;
    padding: 8px 12px;
    border-radius: 16px;
    background-color: #eddfff;
 }

.div-porcentagem{
    width: 111px;
    height: auto;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}


.btn-promotion {
    width: 200px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 64px;
    background-color: #fff;
    text-decoration: none;
    margin: 12px 84px 0 0;
    padding: 12px 32px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #d7232c;
}
.seta {
    width: 20px;
    height:22px;
    color: #353535;
    margin-right:12px;
    margin-left:12px;
    margin-top: 0px;
    margin-bottom: 70px;
    text-align: left;
    align-self: center;
    color: #353535; 
     }

     .porcentagem-pagamento-online {
        width: 252px;
        /* height: 16px; */
        margin: 16px 13px 0px 0;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
    }

    .delivery-text {
        width: auto;
        height: 16px;
        margin: 24px 16px 24px 0;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #aaa;
    }


}
  
  






  

