

.cidade-escolhida-div{
    width: auto;
    height: auto;
    padding: 32px 0 56px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:16px;
    /* border: solid 1px hotpink; */
}

.red-location-icon {
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 8px;
    background-color: #ffe8e9;
}

.cidade-escolhida {
    width: auto;
    height: 32px;
    font-family: Montserrat;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.faça-como {
    width: 141px;
    height: 24px;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}

.carousel-cidade {
    width: 600px;
    height: 168px;
    display: flex;
    margin-top: 24px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    object-fit: contain;
    margin: 0 0 80px 0;
    /* border: solid 1px hotpink; */

}

.keen-slider__slide  {
    width: 222px !important; 
}

.container-rest {
    width: 222px;
    height: 136px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    /* padding: 24px 40px 24px 24px; */
    object-fit: contain;
    border-radius: 16px;
    border: solid 1px #eee;
    background-color: #fff;
    /* border: solid 1px rgb(230, 26, 26); */

}

.rest-cidade-img {
    width: 56px;
    height: 56px;
    flex-grow: 0;
    object-fit: contain;
    border-radius: 8px;
    margin: 24px 0 0px 24px;
}

.nome-rest {
    width: 158px;
    height: 16px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    margin: 0 0 0 24px;
}

/* 
===========================
||       RESPONSIVO      ||
=========================== 
*/

@media only screen and (min-width: 360px) and (max-width: 1192px){

    .red-location-icon {
        width: 32px;
        height: 32px;
    }
    
    .cidade-escolhida-div{
        width: auto;
        height: auto;
        padding: 16px 0 24px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        /* border: solid 1px hotpink; */
    }

    .cidade-escolhida-div{
    width: auto;
    height: auto;
    padding: 24px 0 24px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:12px;
    /* border: solid 1px hotpink; */
}
    
    .cidade-escolhida {
        width: auto;
        height: 16px;
        object-fit: contain;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #000;
    }
    
    .faça-como {
        width: 82px;
        height: 16px;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
    }
    
    .carousel-cidade {
        width: 100%;
        height: 168px;
        display: flex;
        margin-top: 8px;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        object-fit: contain;
        margin: 0 0 8px 0;
        /* border: solid 1px hotpink; */
    }
    
    .container-rest {
        width: 222px;
        height: 136px;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
        /* padding: 24px 40px 24px 24px; */
        object-fit: contain;
        border-radius: 16px;
        border: solid 1px #eee;
        background-color: #fff;
    }
    
    .rest-cidade-img {
        width: 56px;
        height: 56px;
        flex-grow: 0;
        object-fit: contain;
        border-radius: 8px;
        margin: 24px 0 0px 24px;
    }
    
    .nome-rest {
        width: 158px;
        height: 16px;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        margin: 0 0 0 24px;
    }

}