

.img-marcas {
    width: 144px;
    height: 72px;
    opacity: 0.2;
    filter: grayscale(100%);
}

.img-marcas:hover{
    opacity: 1.0;
    filter: grayscale(0%);

}

/* .keen-slider{
    opacity: 0.2 !important;
    filter: grayscale(100%) !important;

} */

/* .keen-slider{
    opacity: 1.0 !important;
    filter: grayscale(0%) !important;
} */

@media only screen and (min-width: 360px) and (max-width: 1192px){

.img-marcas {
    width: 144px;
    height: 72px;
    opacity:unset;
    filter: none;
}

}