*{
  padding: 0;
  margin: 0;
  
}

.header {
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
}

.header-container{
  width: 1192px;
  height: auto;
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  justify-content: space-between;
  /* flex-wrap:wrap; */
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 16px 0px;
  background-color: #fff;
  /* border: solid 1px black; */
}

@media only screen and (max-width: 1192px){

.header-container{
      width: 100%;
  }
}

.logo-vermelho {
  width: 159px;
  height: 48px;
  margin: 0 15.3px 4px 364px;
  margin: 0 15.3px 4px 0px;
  object-fit: contain;
}

/* selecione sua cidade*/

.location-container {
  width: auto;
  height: 48px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;
  /* border: solid 1px rgb(29, 15, 224); */
}

.location-text {
  width: auto;
  height: 16px;
  flex-grow: 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.location-icon {
  width: 40px;
  height: 40px;
  flex-grow: 0;
  object-fit: contain;
  justify-content: center;
}

.arrow-icon {
  width: 40px;
  height: 40px;
  flex-grow: 0;
  object-fit: contain;
}

.location-container:hover {
  border-radius: 40px;
  background-color: #f1f1f1;  
}

.location-container:active {
  border-radius: 40px;
  background-color: #ffe8e9;
}

.location-container:active .location-text {
  color: #d7232c;
  border-radius: 40px;
  background-color: #ffe8e9;
}

.location-container:active .arrow-icon {
  transform: rotate(-180deg);
}

/*Porque appedidos*/

.porque-appedidos-container {
  width: 217px;
  height: 48px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  cursor: pointer;
  /* border: solid 1px rgb(224, 15, 154); */
}

.porque-appedidos-text {
  width: 189px;
  height: 16px;
  flex-grow: 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.porque-appedidos-container:hover {
  border-radius: 40px;
  background-color: #f1f1f1;  
}

.porque-appedidos-container:active {
  border-radius: 40px;
  background-color: #ffe8e9;
}

.porque-appedidos-container:active .porque-appedidos-text {
  color: #d7232c;
  border-radius: 40px;
  background-color: #ffe8e9;
}

/* Como Funciona*/

.como-funciona-container {
  width: 128px;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  cursor: pointer;
  /* border: solid 1px rgb(77, 236, 37); */
}

.como-funciona-text {
  width: 96px;
  height: 16px;
  flex-grow: 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.como-funciona-container:hover {
  border-radius: 40px;
  background-color: #f1f1f1;  
}

.como-funciona-container:active {
  border-radius: 40px;
  background-color: #ffe8e9;
}

.como-funciona-container:active .como-funciona-text {
  color: #d7232c;
  border-radius: 40px;
  background-color: #ffe8e9;
}

/*O que falam*/

.o-que-falam-container {
  width: 109px;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  cursor: pointer;
  /* border: solid 1px rgb(236, 223, 37); */
}
 
.o-que-falam-text {
  width: 77px;
  height: 16px;
  flex-grow: 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.o-que-falam-container:hover {
  border-radius: 40px;
  background-color: #f1f1f1;  
}

.o-que-falam-container:active {
  border-radius: 40px;
  background-color: #ffe8e9;
}

.o-que-falam-container:active .o-que-falam-text {
  color: #d7232c;
  border-radius: 40px;
  background-color: #ffe8e9;
}

/*Dúvidas*/

.duvidas-container {
  width: 84px;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  cursor: pointer;
  /* border: solid 1px rgb(236, 37, 37); */
}
 
.duvidas-text {
  width: 52px;
  height: 16px;
  flex-grow: 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.duvidas-container:hover {
  border-radius: 40px;
  background-color: #f1f1f1;  
}

.duvidas-container:active {
  border-radius: 40px;
  background-color: #ffe8e9;
}

.duvidas-container:active .duvidas-text {
  color: #d7232c;
  border-radius: 40px;
  background-color: #ffe8e9;
}
 
.cadastrar-agora-text {
  width: 103px;
  height: 16px;
  flex-grow: 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
} 

.cadastrar-agora-text:hover{
  background-color: #c32027;
}
 
.btn-cadastrar {
  width: 167px;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* margin: 0 364px 4px 31.3px; */
  /* padding: 16px 32px; */
  border-radius: 64px;
  background-color: #d7232c;
  cursor: pointer;
}

.line-div {
  width: 100%;
  height: 1px;
  background-color: #eee;
}

/* MODAL LOCALIZAÇÃO */


.modal-selecione-sua-cidade{
  /* width: 100vw; */
  height: 100vh;
  width: 100%;
  /* height: auto; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: absolute;
  top: 0;
  left: 0px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.4);
  align-items: flex-end;
  overflow:auto;  -webkit-animation: fadeIn 0.3s;
  animation: fadeIn 0.3s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}  



.escondidoDireita {
  /* -webkit-transform: translateX(100vw);
          transform: translateX(100vw); */
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  animation: fadeOut ease 0.4s;
  -webkit-animation: fadeOut ease 0.4s;
  -moz-animation: fadeOut ease 0.4s;
  -o-animation: fadeOut ease 0.4s;
  -ms-animation: fadeOut ease 0.4s;
  
}

@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}


.modal{
  width: 903px;
  height: auto;
  background-color: #fff;
  border-top-left-radius: 30px;
}

.modal-content{
  width: 399px;
  height: auto;
  margin-left: 56px;
}

.closeBtn {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin-top: 72px;
  /* margin-bottom: 40px; */
  /* font-size: 2.5rem;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; */
  cursor: pointer;
}

.modal-title {
  width: 399px;
  height: 144px;
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  margin-top: 40px;
}

.modal-title .text-style-1 {
  font-weight: 600;
}

.modal-title .text-style-2 {
  font-weight: bold;
  color: #d7232c;
}

.estado-modal {
  width: 241px;
  height: 24px;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  margin-bottom: 32px;
}

.margin-sp{
  margin-top: 56px;
}

.cidade-modal-container-sp{
  width: 450px;
  height: auto;
  display: flex;
  margin-bottom: 64px;
  flex-wrap: wrap;
  gap: 16px;
}

.cidade-border{
  display: flex;
  justify-items: center;
  border-radius: 32px;
  border: solid 1px #eee;
  background-color: #fff;
  padding: 16px 24px;
  font-weight: 600;
  font-size: 16px;
  color: #707070;
  outline: none
}

.cidade-border:hover {
  background-color: #f1f1f1;  
}


.cidade-modal-container-ms{
  width: auto;
  height: auto;
  display: flex;
  justify-content: flex-start;
  gap:16px;
  margin-bottom: 56px;
}


.alterar-location-btn {
  width: 181px;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  object-fit: contain;
  border-radius: 64px;
  background-color: #d7232c;
  margin-bottom: 56px;
  cursor: pointer;
}

.alterar-location-text {
  width: 137px;
  height: 16px;
  flex-grow: 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.sugerir-cidade-conainer{
  width: 254px;
  height: 64px;
  margin-bottom: 128px;
}

.sugerir-cidade-text {
  width: 254px;
  height: 64px;
  flex-grow: 0;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #a49b9b;
}

.sugerir-cidade-text .text-style-2 {
  font-weight: 600;
  color: #000;
  text-decoration: none;
}
.sugerir-cidade-text .text-style-2:hover {
color: #c32027;
}

.citySelected {
  background-color: #ffe8e9;
  color: #d7232c;
}
.citySelected:hover{
  background-color: #ffe8e9;
  color: #d7232c;
}
















/* 
===========================
||       RESPONSIVO      ||
===========================
*/

@media only screen and (min-width: 360px) and (max-width: 1192px){

  .header-container{
    width: 360px;
    height: auto;
    justify-content: space-between;
  }
  .no-show {
    display: none;
  }

  .logo-vermelho {
    width: 106px;
    height: 32px;
    flex-grow: 0;
    /* margin: 4px 53px 15px 24px; */
    margin-left: 24px;
    object-fit: contain;
  }

  .location-container{
    width: auto;
    height: 40px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0px;
    /* margin: 0 16px 11px 53px; */
    margin-right: 16px;
  }

  .location-icon{
    width: 28px;
    height: 28px;
  }

  .arrow-icon{
    width: 28px;
    height: 28px;
  }

  .location-text {
    width: auto;
    height: 16px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }  

  /* RESPONSIVO MODAL LOCALIZAÇÃO */

  .modal-selecione-sua-cidade{
    display: block;
    height: 100%;
  }

  .modal{
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 0px;
    position: fixed;
    /* overflow: visible; */
  }

 body .modal {
    overflow: hidden;
    height: 100%
  }

  .modal-content{
    width: 328px;
    height: auto;
    margin-left: 16px;
  }

  .closeBtn {
    margin-top: 16px;
    object-fit: contain;
  }

  .modal-title {
    width: 328px;
    height: 64px;
    margin: 16px 0px 40px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }

  .estado-modal {
    width: 161px;
    height: 16px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    margin-bottom: 24px;
    margin-top: 32px;
  }

  .cidade-modal-container-sp{
    width: 340px;
    gap:8px;
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  .cidade-border{
    padding: 12px 16px;
    font-size: 12px;
  }

  
  .cidade-modal-container-ms{
    gap:8px;
    margin-bottom: 40px;
  }

  .alterar-location-btn {
    width: 328px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    object-fit: contain;
    border-radius: 64px;
    background-color: #d7232c;
    margin-bottom:16px;
  }
  
  .sugerir-cidade-conainer{
    width: 328px;
    display: flex;
    justify-content: center;
    margin-bottom: 82px;
  }

  .sugerir-cidade-text {
    width: 223px;
    height: 48px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #a49b9b;
  }
  
  .sugerir-cidade-text .text-style-1 {
    font-weight: normal;
  }
  
  .sugerir-cidade-text .text-style-2 {
    font-weight: 600;
    color: #000;
  }




}


/* POPUPS */


/* Popup container */
.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* The actual popup (appears on top) */
.popup .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #aaa;
  color: #fff;
  text-align: center;
  border-radius: 60px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
  font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #aaa transparent transparent transparent;
}

/* Toggle this class when clicking on the popup container (hide and show the popup) */
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s
}

.popup .hide {
  visibility: hidden;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}  
