.main-container-como-funciona{
    width: 1192px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    /* border: solid 1px black; */
}

.gray-line-como-funciona {
    width: auto;
    height: 1px;
    margin: 156px 0;
    background-color: #eee;
}

.como-funciona-container2 {
    width: 398px;
    height: 224px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    margin: 0px 7px 64px 0px;
    padding: 0;
    /* border: solid 1px purple; */
}

.como-funciona-title {
    width: 398px;
    height: 144px;
    flex-grow: 0;
    object-fit: contain;
    font-family: Montserrat;
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
  
.como-funciona-title2 {
    width: 322px;
    height: 48px;
    flex-grow: 0;
    object-fit: contain;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}

.passos-container {
    width: 398px;
    height: 184px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    /* margin: 64px 52px 64px 364px; */
    margin: 64px 0px 0px 0px;
    padding: 0;
    object-fit: contain;
}

.passos-title-box{ 
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 24px 0;

}

.passos-title {
    width: 391px;
    height: 24px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    margin-left: 15px;
    /* border: solid 1px rgb(55, 0, 255); */
}

.numero-icon{
    width: 32px;
    height: 32px;
    flex-grow: 0;
    display: inline-block;
    vertical-align: text-bottom;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 8px; */
    object-fit: contain;
    border-radius: 24px;
    background-color: #ffe8e9;
}

.passos-text1 {
    width: 398px;
    height: 128px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    /* border: solid 1px #cf1190; */
}

.passos-text2 {
    width: 482px;
    height: 128px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    /* border: solid 1px #cf1190; */
}

.passos-text3 {
    width: 398px;
    height: 128px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    /* border: solid 1px #cf1190; */
}

.passos-arrow-icon {
    width: 77px;
    height: 77px;
    margin: 64px 16px 0px 0px;
    background-color: #fff;
}

.passos-arrow-icon:hover {
    border-radius: 64px;
    background-color: #f1f1f1;
}

.passos-arrow-icon:active{
    background-color: #ffe8e9;
}

.cursor-como-funciona{
    cursor: pointer;
}

.como-funciona-img-container{
    width: auto;
    height: auto;
    margin-top: -514px;
    display: flex;
    justify-content: flex-end;
    
}

.como-funciona-img {
    width: 685px;
    height: 613px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    margin: 40px 0px 0px 0;
    padding-left:109px;
    /* padding: 0; */
    object-fit: contain;
    /* border: solid 1px #485306; */
}

/* 
===========================
||       RESPONSIVO      ||
===========================
*/

@media only screen and (min-width: 360px) and (max-width: 1192px){

    .main-container-como-funciona{
        width: 328px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
    }

    .no-show {
        display: none;
    }

    .gray-line-como-funciona {
        margin: 56px 0;
    }

    .como-funciona-container2 {
        width: 250px;
        height: 104px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
        /* margin: 56px 97px 40px 16px; */
        margin-bottom: 40px;
        padding: 0;
        order: 1;
    }

    .como-funciona-title {
        width: 250px;
        height: 72px;
        flex-grow: 0;
        object-fit: contain;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #000;
    }

    .como-funciona-title2 {
        width: 188px;
        height: 16px;
        flex-grow: 0;
        object-fit: contain;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
    }

    .como-funciona-img {
        width: 328px;
        height: 294px;
        /* margin: 40px 16px 32px; */
        object-fit: contain;
        margin:0;
        padding: 0;
        border:none;
    }

    .como-funciona-img-container{
        /* width: auto;
        height: auto;
        margin-top: -514px;
        display: flex;
        justify-content: flex-end; */
        width: 328px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
        margin-bottom: 0; 
        order: 2;       
    }

    .box-passos-como-funciona{
        width: 328px;
        height: auto;
    }

    .passos-container {
        width: 328px;
        height: 148px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 12px;
        /* margin: 32px 16px; */
        margin-top: 32px;
        padding: 0;
        object-fit: contain;
        order: 3;
    }

    .numero-icon {
        width: 24px;
        height: 24px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        /* padding: 6px 4px; */
        object-fit: contain;
        border-radius: 24px;
        background-color: #ffe8e9;
    }

    .passos-title {
        width: 391px;
        height: 16px;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        margin-left: 12px;
    }

    .passos-text1 {
        width: 326px;
        height: 112px;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
        visibility: hidden; 
        position: relative; 
    }

    .passos-text1:after {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        content:"Cliente seleciona um restaurante no\a aplicativo, olha o cardápio e pode visualizar os produtos, tempo de entrega, forma de pagamento para realizar o pedido.";
        white-space: pre-wrap;
    }

    .passos-text2 {
        width: 326px;
        height: 112px;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
        visibility: hidden;
        position: relative;
    }

    .passos-text2:after {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        content: "Texto2";
        content:"O restaurante recebe o pedido pelo celular\a ou computador. Todas as informações do cliente estão disponíveis. Nome do cliente, endereço, item e valores. O pedido é enviado para o preparo.";
        white-space: pre-wrap;
    }

    .passos-text3 {
        width: 326px;
        height: 112px;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
    }

    .passos-arrow-icon {
        width: 48px;
        height: 48px;
        margin: 32px 16px 56px 0px;
        background-color: #fff;
    }

    .espaco-mobile-gray-line{
        margin: 156px 0;
    }
   
}