.Footerdesktop {
    width: 100%;
    height: auto;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    background-color: #1c0506;;
}
  
.footer-container{
    width: 1192px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 72px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
    background-color: #1c0506;;
}
  
.logocompletobranco {
    width: 240px;
    height: 72px;
    margin-top: 104px;
    margin-bottom: 72px;
    flex-grow: 0;
    object-fit: contain;
}
  
.links {
    width: 1091px;
    height: 232px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 119px;
    padding: 0;
    margin-top: -72px;
}
  
.footer-appedidos-texts {
    width: 185px;
    height: 224px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    padding: 0;
}

.appedidos-title {
    width: 133px;
    height: 24px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
  
.div-sobre-nos {
    width: 100px;
    height: 40px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px; 
}

.text-sobre-nos {
    width: 100px;
    height: 24px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #a49b9b;
    text-decoration: none;
}

.text-sobre-nos:hover{
    text-decoration: underline;
    color: #fff;
}

.div-politica {
    width: 185px;
    height: 40px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.text-politica {
    width: 185px;
    height: 24px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #a49b9b;
    text-decoration: none;
}

.text-politica:hover{
    text-decoration: underline;
    color: #fff;
}
  
.div-termos {
    width: 181px;
    height: 40px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

}

.text-termos {
    width: 181px;
    height: 24px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #a49b9b;
    text-decoration: none;
}

.text-termos:hover{
    text-decoration: underline;
    color: #fff;
}
  
.footer-restaurante-texts {
    width: 234px;
    height: 232px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    padding: 0;
}
  
.restaurante-title {
    width: 155px;
    height: 24px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
  
.div-blog {
    width: 38px;
    height: 40px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.text-blog {
    width: 38px;
    height: 24px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #a49b9b;
    text-decoration: none;
}

.text-blog:hover{
    text-decoration: underline;
    color: #fff;
}
  
.div-portal {
    width: 178px;
    height: 40px;
    flex-grow: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.text-portal {
    width: 173px;
    height: 24px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #a49b9b;
    text-decoration: none;
}

.text-portal:hover{
    text-decoration: underline;
    color: #fff;
}
  
.text-cadastrar {
    width: 170px;
    height: 16px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    text-decoration: none;
}

.footer-btn-cadastrar {
    width: 234px;
    height: 48px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* padding: 16px 0px; */
    border-radius: 64px;
    background-color: #d7232c;
}

.footer-btn-cadastrar:hover {
    background-color: #c32027;
  }
  
.footer-redes-sociais {
    width: 338px;
    height: 104px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    padding: 0 0 0 50px;
}
  
.redes-sociais-title {
    width: 172px;
    height: 24px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
  
.icon-redes-sociais {
    width: 48px;
    height: 48px;
    margin: 0;
    gap:20px;
    background-color: #fff;
    background-color: #1c0506
}

.icon-redes-sociais:hover {
    border-radius: 48px;
    background-color: rgba(255, 255, 255, 0.2);
}
  
.icons-container{
    width: 288px;
    /* height: 48px; */
    height: auto;
    display: flex;
    justify-content: space-between;
}

.footer-gray-line {
    width: 100%;
    height: 1px;
    flex-grow: 0;
    background-color: rgba(255, 255, 255, 0.2);
}

.baixe-o-app-container {
    width: 964px;
    height: 120px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    gap: 48px;
    margin-bottom: 104px;
  }

.baixe-o-app {
    width: 167px;
    height: 24px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
  
.div-icon-baixar-app {
    width: auto;
    height: 48px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    padding: 0;
}

.container-lojas {
    width: 340px;
    height: 48px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 0;
}
  
.icon-baixar-app {
    width: 162px;
    height: 48px;
    background-color: #000;
}

.icon-baixar-app:hover {
    background-color: rgba(255, 255, 255, 0.1);
    opacity: 0.8;
    border-radius: 5px;
}


.copyright-container{
    width: 560px;
    height: 48px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 64px;
    
}
  
.copyright-text {
    width: 560px;
    height: 24px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: right;
    color: #a49b9b;
}




/* 
===========================
||       RESPONSIVO      ||
===========================
*/

@media only screen and (min-width: 360px) and (max-width: 1192px){

    .footer-container {
        width: 328px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 56px;
        background-color: #1c0506;
        margin-bottom: 56px;
    }

    .logocompletobranco {
        width: 240px;
        height: 72px;
        flex-grow: 0;
        object-fit: contain;
        margin: 56px 0;
    }

    .links {
        width: 328px;
        height: 705px;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 56px;
        padding: 0;
    }

    .footer-redes-sociais {
        width: 288px;
        height: 104px;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 32px;
        padding: 0;
        margin-bottom: 89px;
    }

    .baixe-o-app-container {
        width: 328px;
        height: 256px;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 48px;
        padding: 0;
        margin-bottom: 56px;
    }

    .div-icon-baixar-app {
        width: 328px;
        height: 184px;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 24px;
        padding: 0;
    }

    .container-lojas {
        width: 162px;
        height: 112px;
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        padding: 0;
      }

    .copyright-container {
        width: 340px;
        margin-left: 0;
    }

    .copyright-text {
        width: 327px;
        height: 48px;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #a49b9b;
    }
  
}