#desktop-hide-faq{
    display: none ;
}


.main-container-faq{
    width: 1192px;
    height: auto; /*auto*/
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    /* border: solid 1px black; */
}

.gray-line-faq {
    width: 100%;
    height: 1px;
    margin: 156px 0;
    background-color: #eee;
}

.duvidas-frequentes-title {
    width: 403px;
    height: 144px;
    margin: 0px 2px 0px 0px;
    font-family: Montserrat;
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
  
.duvidas-frequentes-text {
    width: 478px;
    height: 48px;
    margin: 32px 28px 0px 0px;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}

.faq-container {
    width: auto;
    /* height: 272px; */
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 72px; */
    /* margin: 40px 0 0; */
    border-bottom: solid 1px #eee;
    cursor: pointer;
} 

.faq-container:hover {
    background-color: #fafafa;  
  }

.faq-box{
    width: 1192px;
    height: auto; 
    margin-top: 72px;
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    /* border: solid 1px rgb(240, 236, 8); */
}
  
.faq-emoji {
    width: 56px;
    height: 56px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 24px;
    padding: 0;
    object-fit: contain;
    background-color: #fff;
    border-radius: 64px;
}
  
.faq-pergunta {
    width: auto;
    height: 48px;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    /* margin-top: 72px; */
    color: #000;
    /* border: solid 1px rgb(27, 126, 7); */
}

/* width considerada somente na versão mobile */
#faq-question1:not(.faq-question-mobile1){
    width: 200px;
}

#faq-question2:not(.faq-question-mobile2){
    width: 250px;
}

#faq-question3:not(.faq-question-mobile3){
    width: 250px;
}

#faq-question4:not(.faq-question-mobile4){
    width: 200px;
}

#faq-question5:not(.faq-question-mobile5){
    width: 200px;
}

#faq-question6:not(.faq-question-mobile6){
    width: 200px;
}

.plus-icon {
    width: 48px;
    height: 48px;
    flex-grow: 0;
    object-fit: contain;
}

.faq-answer {
    width: 1091px;
    height: auto;
    flex-grow: 0;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}
  
.faq-answer .text-style-1 {
    color: #000;
    text-decoration: underline;
}


.text-style-1 a,
.text-style-1 a:hover,
.text-style-1 a:focus,
.text-style-1 a:active,
.text-style-1 a:link {
  text-decoration: none;
  color: #000;
    text-decoration: underline;
  
}

.question-section {
background: transparent;
/* border: 1px solid lightgray; */
/* border-radius: 8px; */
/* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
cursor: pointer;
width: 1192px;
border: none;
}

.question-icon {
    background: none;
    border: none;
    color: #0d0d0e;
    cursor: pointer;
    font-size: 22px;
    margin-left: auto;
    margin-right: 8px;
}

.question-icon:active {
    color: #d7232c;
    border-radius: 40px;
    background-color: #ffe8e9;
}
  
  
.answer {
    max-height: 0;
    height: auto;
    overflow: hidden;
    transition: max-height 0.6s ease;
    text-align: left;
}

/* 
===========================
||       RESPONSIVO      ||
===========================
*/

@media only screen and (min-width: 360px) and (max-width: 1192px){
    .gray-line-faq {
        width: 100%;
        height: 1px;
        margin: 56px 0;
        background-color: #eee;    
    }

    .main-container-faq{
        width: 328px;
        height: auto; /*auto*/
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 24px;
        /* border: solid 1px black; */
    }

    .duvidas-frequentes-title {
        width: 230px;
        height: 72px;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #000;
    }

    .duvidas-frequentes-text {
        width: 300px;
        height: 28px;
        margin: 16px 0px 0px 0px;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
    }

    .faq-container {
        /* width: 360px;
        height: 176px; */
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        /* gap: 24px;
        margin: 40px 0 0; */
    }

    .faq-box {
        width: 328px;
        height: auto;
        margin-top: 24px;
        margin-bottom: 0px;
        /* display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start; */
        /* gap: 24px;
        margin: 40px 0 0;
        padding: 24px 16px 0; */
        
        /* border: solid 1px rgb(240, 236, 8); */
    }

    .faq-emoji {
        width: 48px;
        height: 48px;
        margin-bottom: 16px;
        background-color: #fff;
    }

    .question-section {
        background: transparent;
        /* border: 1px solid lightgray; */
        /* border-radius: 8px; */
        /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
            rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
        cursor: pointer;
        width: 328px;
        border: none;
        }

    .faq-pergunta {
        width:  auto;
        height: 64px;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #000;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0;
       
    }

    .faq-question-mobile1{
        width: auto;
        /* border: solid 1px blue; */
    }

    .faq-question-mobile2{
        width: auto;
        /* border: solid 1px blue; */
    }

    .faq-question-mobile3{
        width: 250px;
        /* border: solid 1px blue; */
    }

    .faq-question-mobile5{
        width: 250px;
        /* border: solid 1px blue; */
    }

    .faq-question-mobile6{
        width: 250px;
        /* border: solid 1px blue; */
    }

    .faq-answer {
        width: 290px;
        height: auto;
        flex-grow: 0;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        color: #707070;
    }

    /* .faq-answer-mobile1{
        visibility: hidden;
        position: relative;
    }

    .faq-answer-mobile1:after {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        content: "O pagamento pode ser feito diretamente \A pelo aplicativo (pagamento online) ou para \A o restaurante, no momento da entrega do \A pedido";
        white-space: pre-wrap;
    } */

    /* .faq-answer-mobile2{
        visibility: hidden;
        position: relative;
    }

    .faq-answer-mobile2:after {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        content: "Os pagamentos feitos diretamente pelo\A aplicativo (pagamento online) serão \A repassados ao restaurante, já descontando \A os pagamentos feitos diretamente \A pro restaurante (no momento da entrega), \A no prazo de 30 dias, com dois repasses \A mensais.";
        white-space: pre-wrap;
    } */

    /* .faq-answer-mobile3{
        visibility: hidden;
        position: relative;
    }

    .faq-answer-mobile3:after {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        content: "Todos os pagamentos efetuados entre\A os dias 1 e 15 serão repassados no 1º dia útil\A do próximo mês. Já os pagamentos\A efetuados entre os dias 16 até o último dia\A do mês serão repassados no 16º dia útil\A do próximo mês. O valor será depositado\A na conta bancária indicada no cadastro.";
        white-space: pre-wrap;
    } */

    .faq-answer-mobile4{
        visibility: hidden;
        position: relative;
    }

    .faq-answer-mobile4:after {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        content: "Sim, é necessário que o restaurante já\A tenha o serviço de entrega.";
        white-space: pre-wrap;
    }

    .faq-answer-mobile6{
        visibility: hidden;
        position: relative;
    }

    .faq-answer-mobile6:after {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        content: "Você irá receber os pedidos no seu celular\A e também no seu computador, através do Gestor de Negócios. É importante ter um dispositivo exclusivo da loja.";
        white-space: pre-wrap;
    }

    .faq-answer-mobile7{
        visibility: hidden;
        position: relative;
    }

    .faq-answer-mobile7:after {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        content: "Você deverá se comprometer a atender e entregar pelo menos 90% dos pedidos realizados pelos clientes";
        white-space: pre-wrap;
    }

    .faq-answer-mobile8{
        visibility: hidden;
        position: relative;
    }

    .faq-answer-mobile8:after {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        content: "Os pedidos deverão ser recebidos em até 8 minutos, caso contrário a plataforma cancela o pedido e notifica o cliente. Restaurantes com ocorrência de pedidos cancelados (pela plataforma) perdem visibilidade dentro do aplicativo e podem ser desativados.";
        white-space: pre-wrap;
    }

    
}



  